import './src/style/global.css';
// import './src/assets/css/style.css';
// import './src/assets/css/responsive.css';

// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
// }

// const onClientEntry = () => {
//     if (typeof window !== 'undefined') {
//         window.onload = () => {
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/jquery.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/bootstrap.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/bootstrap-select.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/owl.carousel.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/isotope.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/jquery.magnific-popup.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/waypoints.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/jquery.counterup.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/wow.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/jquery.easing.min.js")
//             addScript("https://s3.eu-west-2.amazonaws.com/www.grocy.shop/assets/js/custom.js")
//         }
//     }
// }

// export {
//     onClientEntry
// }
